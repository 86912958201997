import React from "react"
import { Link } from "gatsby"

// Components
import { ArrowIcon, ArrowHoverIcon } from "../icons"

// Stylesheet
import styles from "./index.module.scss"

// Images
import noImg from "../../images/no-image.png"

const ArticleCard = ({
  data: {
    title = "Tribute to my Mom",
    imageUrl,
    description,
    category,
    articleLink,
    authorName,
    author,
  },
}) => {
  const articleImage = imageUrl || noImg

  return (
    <div className={styles.articleCard}>
      <a href={articleLink} target="_blank" rel="noopener noreferrer">
        <div
          className={styles.imageContainer}
          style={{ backgroundImage: `url(${articleImage})` }}
        >
          <ArrowIcon className={`${styles.arrow} ${styles.normal}`} />
          <ArrowHoverIcon className={`${styles.arrow} ${styles.hover}`} />
        </div>
        <div className={styles.details}>
          <h3 className={styles.title}>{title}</h3>
          {category && (
            <span
              className={`${styles.tags} medium`}
              dangerouslySetInnerHTML={{ __html: category }}
            />
          )}
          {authorName && (
            <h4 className={styles.author}>
              Posted by: <span>{authorName}</span>
            </h4>
          )}
          {author && (
            <h4 className={styles.author}>
              Posted by: <span>{author}</span>
            </h4>
          )}
          {description?.description && (
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{ __html: description.description }}
            />
          )}
        </div>
      </a>
    </div>
  )
}

export const ArticleViewTributeCard = ({
  data: {
    id,
    author,
    sliderContent,
    mom: { firstName, lastName },
  },
}) => {
  const name = `${firstName} ${lastName}`
  const tributeLink = `/tribute/${firstName.toLowerCase()}-${lastName.toLowerCase()}/${id}`
  return (
    <Link to={tributeLink} className={styles.articleCard}>
      <div
        className={styles.imageContainer}
        style={{ backgroundImage: `url(${sliderContent[0].fluid.src})` }}
      >
        <ArrowIcon className={`${styles.arrow} ${styles.normal}`} />
        <ArrowHoverIcon className={`${styles.arrow} ${styles.hover}`} />
      </div>
      <div className={styles.details}>
        <h3 className={styles.title}>{name}</h3>
        <h4 className={styles.author}>
          Posted by: <span>{author}</span>
        </h4>
      </div>
    </Link>
  )
}

export default ArticleCard
