import React from "react"
import { Link } from "gatsby"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"

// Components
import ArticleCard, {
  ArticleViewTributeCard,
} from "../../../components/articleCard"
import RichTextRenderer from "../../../components/richTextRenderer"
import AdSection from "../../../components/adSection"

// Stylesheet
import styles from "./index.module.scss"

// Images
import stroke from "../../../images/stroke1.svg"

export const ArticlesList = ({ tributesView, data, minimalInfo = false }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      {!data.length ? (
        <h3>No articles in this category.</h3>
      ) : (
        data.map((article, index) => {
          return tributesView ? (
            <ArticleViewTributeCard key={index} data={article.node} />
          ) : (
            <ArticleCard
              key={index}
              data={article.node}
              minimalInfo={minimalInfo}
            />
          )
        })
      )}
    </div>
  )
}

const ArticleSection = ({ data: { title, articlesList } }) => {
  const options = {
    renderNode: {
      [BLOCKS.HEADING_2]: (node, children) => (
        <h2 className={styles.title}>{children}</h2>
      ),
    },
    renderMark: {
      [MARKS.BOLD]: text => {
        return <span className={styles.highlightedText}>{text}</span>
      },
    },
  }

  return (
    <div className={styles.articleSection}>
      <img src={stroke} className={styles.stroke} />
      <div className="max-w-6xl mx-auto px-4 xl:px-0 h-full flex flex-col items-center">
        <RichTextRenderer content={title.raw} options={options} />

        <ArticlesList data={articlesList} />

        <Link to="/articles" className="btn twine-gradient">
          Show More Articles
        </Link>
      </div>
      {/* <AdSection /> */}
    </div>
  )
}

export default ArticleSection
